import { FC, useState } from 'react'
import { Button, InputError, InputLabel, PrimaryButton, Spacer, TextInput, Title } from '../../styles/generic.styles'
import { validateCPF, formatCPF } from './methods'
import { Form } from './EntryPage.styles'
import { API_URL } from '../../constants/apiUrl'

export const EntryPage: FC = () => {
  const [inputCpf, setInputCpf] = useState('')
  const [cpfError, setCPFError] = useState('')

  return (
    <Form action={`${API_URL}/send-cpf?${encodeURIComponent(inputCpf)}`} method="get">
      <Title> Please enter CPF number</Title>

      <Spacer size={40} />

      <InputLabel htmlFor="cpf" required>
        CPF
      </InputLabel>
      <TextInput
        error={!!cpfError}
        name="cpf"
        id="cpf"
        value={inputCpf}
        onChange={({ target }) => {
          const val = target.value

          setCPFError(validateCPF(val))

          setInputCpf(val)
        }}
        maxLength={14}
        onBlur={({ target }) => {
          if (!target.value.includes('.') && !target.value.includes('-')) {
            setInputCpf(formatCPF(target.value))
          }
        }}
      />
      <InputError error={cpfError} />

      <Spacer size={40} />

      <PrimaryButton style={{ marginLeft: 'auto' }} disabled={!!cpfError || !inputCpf}>
        Proceed
      </PrimaryButton>
    </Form>
  )
}
