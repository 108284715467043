import 'mobx-react-lite'
import React from 'react'
import { Helmet } from 'react-helmet'

import FontStyles from '../styles/fonts.styles'
import { ContentRouter } from './ContentRouter'
import { Toasts } from './Toast/Toast'

import { ThemeProvider } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import awsexports from '../aws-exports'

// import AppleTouchIcon from '../assets/favicons/apple-touch-icon.png'
// import Favicon32 from '../assets/favicons/favicon-32x32.png'
// import Favicon16 from '../assets/favicons/favicon-16x16.png'
// import SiteManifest from '../assets/favicons/site.webmanifest'
// import Favicon from '../assets/favicons/favicon.ico'
import { observer } from 'mobx-react'
import { GlobalStyle } from '../styles/App.styles'

Amplify.configure(awsexports)

export const App: React.FC = observer(() => {
  return (
    <>
      <Helmet defaultTitle="Identification">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        {/* <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
        <link rel="manifest" href={SiteManifest} />
        <link rel="shortcut icon" href={Favicon} /> */}
        <meta name="theme-color" content="#ffffff" />

        <meta name="description" content="Onboarding Web" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css2?family=Just+Me+Again+Down+Here&display=swap" rel="stylesheet" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <FontStyles />
      <GlobalStyle />

      <ThemeProvider>
        <div className="App">
          <Toasts />
          <ContentRouter />
        </div>
      </ThemeProvider>
    </>
  )
})
