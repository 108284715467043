import { FC, useState } from 'react'
import {
  FooterBlock,
  HeaderBlock,
  Heading,
  PaddingWrap,
  SeparatorLine,
  SubHeading,
  SuccessWrap,
  DataWrap,
} from './SuccessPage.styles'
import { navigate } from 'wouter/use-browser-location'
import { SecondaryButton, PrimaryButton, Spacer } from '../../styles/generic.styles'

const Checkmark = () => (
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.48682 3.75831L3.92626 6.19784L8.41317 1.71094"
      stroke="#437BE5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SuccessPage: FC = () => {
  const [step, setStep] = useState<'share' | 'success'>('share')

  const urlParams = new URLSearchParams(window.location.search)
  const name = urlParams.get('name')
  const cpf = urlParams.get('cpf')
  const dob = urlParams.get('dateOfBirth')

  const registrationStatusCode = urlParams.get('registrationStatusCode')
  const registrationStatusDescription = urlParams.get('registrationStatusDescription')

  const ShareStep = () => (
    <>
      <HeaderBlock>Identity verification completed successfully</HeaderBlock>

      <DataWrap>
        <Heading>Registration status:</Heading>
        <SubHeading>Code - {registrationStatusCode}</SubHeading>
        <SubHeading>Description - {registrationStatusDescription}</SubHeading>

        <Spacer size={40} />

        <Heading>You are about to share the following data:</Heading>

        <SubHeading>
          <Checkmark />
          Name - {name}
        </SubHeading>

        <SubHeading>
          <Checkmark />
          Date of birth - {dob}
        </SubHeading>

        <SubHeading>
          <Checkmark />
          CPF - {cpf}
        </SubHeading>

        <SeparatorLine />
      </DataWrap>

      <FooterBlock>
        <SecondaryButton
          onClick={() => {
            navigate('/')
          }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            setStep('success')
          }}
        >
          Share
        </PrimaryButton>
      </FooterBlock>
    </>
  )

  const SuccessStep = () => (
    <PaddingWrap>
      <SuccessWrap>
        <svg version="1.1" viewBox="0 0 50 50" xmlSpace="preserve" width="150px" height="150px">
          <circle fill="#25AE88" cx="25" cy="25" r="25" />
          <polyline
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
	38,15 22,33 12,25 "
          />
        </svg>
        <h2>Identification process completed</h2>
      </SuccessWrap>
    </PaddingWrap>
  )

  return (
    <>
      {step === 'share' && <ShareStep />}
      {step === 'success' && <SuccessStep />}
    </>
  )
}
