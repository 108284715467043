import { observer } from 'mobx-react'
import styled from 'styled-components'

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const NotFoundPage = observer(() => {
  return <Page className="not-found">Not found</Page>
})
