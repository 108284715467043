import { styled } from 'styled-components'

export const HeaderBlock = styled.div`
  height: 120px;
  background-color: #003580;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 20px;
`

export const Heading = styled.h2`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #1d2129;
  margin-bottom: 12px;
`

export const SubHeading = styled.h3`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #65676b;

  display: flex;
  gap: 6px;
  align-items: center;
`

export const Form = styled.form`
  padding: 50px;
  width: 100%;
  height: 100%;
`

export const PaddingWrap = styled.div`
  padding: 50px;
  width: 100%;
  height: 100%;
`

export const LivenessCheckerWrap = styled.div`
  padding-bottom: 50px;
`
