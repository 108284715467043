import styled, { css } from 'styled-components'

export const Animatable = css`
  ${css`
    transition: all 0.5s ease-out;
  `}
`

export const HideOnMobile = css`
  @media (max-width: 980px) {
    display: none;
  }
`

export const HideOnDesktop = css`
  @media (min-width: 981px) {
    display: none;
  }
`

export const Spacer = styled.div<{ margin?: string; size?: number; width?: string; $desktopOnly?: boolean }>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ margin }) => css`
    margin: ${margin || '0'};
  `}

  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
      min-height: ${size}px;
    `}

  ${({ $desktopOnly }) =>
    $desktopOnly &&
    css`
      @media (max-width: 980px) {
        display: none;
      }
    `}
`

export const RouterContainer = styled.div.attrs({
  className: 'content-router',
})`
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
  }

  background-color: #f8f7f6;
  font-family: 'Roboto', sans-serif;

  * {
    box-sizing: border-box;
  }
`

export const ContentWrap = styled.div<{ width?: string }>`
  position: relative;
  background-color: #fff;
  /* padding: 50px; */
  max-width: ${({ width }) => width || '510px'};
  width: 100%;
  /* height: 605px; */
  border-radius: var(--Spacing-3xs, 2px);
  border: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;

  button {
    transition: all 0.2s ease-in-out;
  }

  a {
    font-weight: 700;
  }
`

export const Title = styled.h1`
  color: #2a0062;
  font-size: 30px;
  line-height: 1.22;
`

export const Subtitle = styled.h2`
  color: #2a0062;
  font-size: 26px;
  line-height: 1.22;
`

export const SelectInputWrapper = styled.div`
  width: 100%;
  min-height: 40px;

  & > *,
  & > * > * {
    min-height: 40px;
  }

  .authflow-select-input {
    .select-input__control {
      min-height: 40px;

      & > .select-input__value-container {
        padding: 0px 16px;
      }
    }
  }
`

export const TextInputWrapper = styled.div<{ height?: string }>`
  width: 100%;

  & > div {
    & > input {
      height: ${({ height }) => height || '40px'};
    }
  }
`

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  padding: 6px 6px 6px 12px;
  border-radius: 5px;
  background-color: inherit;
  color: #2a0062;
  border: 1px solid #e5e4e9;
  resize: none;

  &:focus {
    color: #2a0062;
  }

  &:disabled {
    color: #98959f;
    border: 1px solid #e5e4e9;
    background-color: #e5e4e9;
  }
`

export const InputLabel = styled.label<{ required?: boolean }>`
  color: #383838;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  display: block;
  margin: 12px 0 14px 0;

  @media (max-width: 550px) {
    margin: 12px 0 4px 0;
  }

  ${({ required }) =>
    required &&
    `
    &:after {
      content: ' *';
      color: #f00;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
    }
  `}
`

export const InputError = styled.span<{ error?: string | boolean }>`
  height: 20px;

  ${({ error }) =>
    error &&
    `
    &:after {
      content: '${error}';
      color: #f00;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
    }
`}
`

export const TextInput = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 42px;
  border-radius: 4px;
  border: ${(props) => (props.error ? '2px solid #f00' : '2px solid #98abee')};
  background: #fff;
  padding: 10px 16px;

  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;

  &[disabled] {
    background: #f8f7f6;
    border: 2px solid #dbdbdb;
    color: #8b8b8b;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 100px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  background-color: #2a0062;
  ${Animatable}
`

export const PrimaryButton = styled.button<{ disabled?: boolean }>`
  width: 115px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  background-color: #437be5;
  font-family: Roboto;

  &:hover {
    opacity: 0.9;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #ccc;
    cursor: not-allowed;
  `}

  ${Animatable}
`

export const SecondaryButton = styled.button`
  width: 115px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #437be5;
  background-color: transparent;
  font-family: Roboto;

  &:hover {
    opacity: 0.9;
    text-decoration: underline;
  }

  ${Animatable}
`
