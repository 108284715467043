import { FC } from 'react'
import { LivenessChecker } from '../../components/LivenessChecker/LivenessChecker'
import { LivenessCheckerWrap } from './ContinueProcessPage.styles'

export const ContinueProcessPage: FC = () => {
  return (
    <LivenessCheckerWrap>
      <LivenessChecker />
    </LivenessCheckerWrap>
  )
}
