export const formatCPF = (cpf: string): string => {
  cpf = cpf.replace(/[.-]/g, '')
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const clearCPF = (cpf: string): string => {
  return cpf.replace(/[.-]/g, '')
}

export const validateCPF = (cpf: string): string => {
  cpf = cpf.replace(/[.-]/g, '')

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return 'Invalid CPF'
  }

  for (let j = 0; j < 2; j++) {
    let sum = 0
    let remainder

    for (let i = 0; i < 9 + j; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 + j - i)
    }

    remainder = (sum * 10) % 11
    if (remainder === 10 || remainder === 11) {
      remainder = 0
    }

    if (remainder !== parseInt(cpf.charAt(9 + j))) {
      return 'Invalid CPF'
    }
  }

  return ''
}
