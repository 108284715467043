import { Route, Switch, useLocation } from 'wouter'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

import { NotFoundPage } from '../pages/NotFoundPage'
import { ContentWrap, RouterContainer } from '../styles/generic.styles'
import { EntryPage } from '../pages/EntryPage/EntryPage'
import { ContinueProcessPage } from '../pages/ContinueProcessPage/ContinueProcessPage'
import { SuccessPage } from '../pages/SuccessPage/SuccessPage'

const LoadWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentRouter: React.FC = observer(() => {
  const [location, setLocation] = useLocation()

  return (
    <RouterContainer>
      <ContentWrap width={location === '/success' ? '640px' : null}>
        <Switch>
          <Route path="/" component={EntryPage} />

          <Route path="/continue" component={ContinueProcessPage} />
          <Route path="/success" component={SuccessPage} />

          {/* Default route in a switch */}
          <Route>
            <LoadWrap>
              <NotFoundPage />
            </LoadWrap>
          </Route>
        </Switch>
      </ContentWrap>
    </RouterContainer>
  )
})
