import { styled } from 'styled-components'
import { Animatable } from '../../styles/generic.styles'

export const HeaderBlock = styled.div`
  height: 208px;
  background-color: #003580;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 25px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  padding: 20px;
`

export const Heading = styled.h2`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #1d2129;
  margin-bottom: 12px;
`

export const SubHeading = styled.h3`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #65676b;

  display: flex;
  gap: 6px;
  align-items: center;
`

export const PaddingWrap = styled.div`
  padding: 50px;
  width: 100%;
  height: 100%;
`

export const DataWrap = styled.div`
  padding: 50px 50px 0 50px;
  width: 100%;
  height: 100%;
`

export const FooterBlock = styled.div`
  width: 100%;
  height: 60px;
  gap: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 50px 50px 50px;
`

export const SuccessWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding: 50px 0;

  h2 {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #000;
  }
`

export const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 25px 0 -50px 0;
`
