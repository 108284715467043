import { styled } from 'styled-components'

export const ComponentRestyleWrap = styled.div`
  .amplify-flex.amplify-liveness-camera-module {
    border: none;
  }

  .amplify-liveness-start-screen-camera-select__container {
    /* height: 350px; */
  }

  .amplify-button.amplify-field-group__control.amplify-button--primary {
    background-color: #437be5;
    color: #fff;
    height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    background-color: #437be5;
  }

  & > div {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .amplify-loader {
    width: 80px;
  }

  .amplify-loader.amplify-loader--large.amplify-liveness-loader {
    transform: translate(0, -200%) !important;
  }

  .amplify-loader circle:last-of-type {
    stroke: #437be5 !important;
  }
`
