import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import { Loader, ThemeProvider } from '@aws-amplify/ui-react'
import { FC, useEffect, useState } from 'react'
import { ComponentRestyleWrap } from './LivenessChecker.styles'
import { axiosInstance } from '../../methods/axiosConfig'
import { navigate } from 'wouter/use-browser-location'

interface LivenessCheckerProps {}

export const LivenessChecker: FC<LivenessCheckerProps> = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [createLivenessApiData, setCreateLivenessApiData] = useState<{
    sessionId: string
  } | null>(null)

  useEffect(() => {
    const fetchCreateLiveness: () => Promise<void> = async () => {
      const { data } = await axiosInstance.post<{ sessionId: string }>(`/init-liveness`)
      setCreateLivenessApiData({ sessionId: data.sessionId })
      setLoading(false)
    }

    fetchCreateLiveness()
  }, [])

  const handleAnalysisComplete: () => Promise<void> = async () => {
    try {
      const { data } = await axiosInstance.post<{
        isLivenessCheckSuccessful: boolean
        livenessCheckConfidence: number
        biovalidSuccess: boolean
        biovalidSimilarity: number
        registrationStatusCode: string
        registrationStatusDescription: string
        user: {
          name: string
          cpfData: string
          dateOfBirth: string
        }
      }>(`/liveness-session-completed`)

      console.log(data)

      navigate(
        `/success?name=${data.user.name}&cpf=${data.user.cpfData}&dateOfBirth=${data.user.dateOfBirth}&registrationStatusCode=${data.registrationStatusCode}&registrationStatusDescription=${data.registrationStatusDescription}`,
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ComponentRestyleWrap>
      <ThemeProvider>
        {loading ? (
          <Loader />
        ) : (
          <FaceLivenessDetector
            sessionId={createLivenessApiData.sessionId}
            region="us-east-1"
            onAnalysisComplete={handleAnalysisComplete}
            onError={(error) => {
              console.error(error)
            }}
          />
        )}
      </ThemeProvider>
    </ComponentRestyleWrap>
  )
}
