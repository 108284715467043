import axios from 'axios'
import { API_URL } from '../constants/apiUrl'
import { toast } from 'react-toastify'

export const axiosInstanceUnauthenticated = axios.create({
  baseURL: API_URL,
})
console.log(API_URL)
export const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
axiosInstance.interceptors.response.use(undefined, async (error: any) => {
  console.error(error)
  // if (error.response && error.response.status === 401 && process.env.STAGE === 'prod') {
  //   if (error.response?.data?.code === 'AuthorizationFailed') {
  //     toast.error(error.response?.data?.message)
  //   } else {
  //     console.log('401 error, redirecting to login page', `${window.location.origin}/login`)
  //     window.location.href = `${window.location.origin}/login`
  //   }
  // }

  return Promise.reject(error)
})
